<template>
	<div class="callScheduler">
		<form class="scheduleCallForm" v-if="showThanks !== true">
			<div class="row formrow">
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-firstname' + oid ">First Name</label>
						<input type="text" :id="'callform-firstname' + oid " v-model="firstname" name="firstname">
					</div>
				</div>
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-lastname' + oid ">Last Name</label>
						<input type="text" :id="'callform-lastname' + oid " v-model="lastname" name="lastname">
					</div>
				</div>
			</div>
			<div class="row formrow">
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-email' + oid ">Email</label>
						<input type="text" :id="'callform-email' + oid " v-model="email" name="email">
					</div>
				</div>
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-state' + oid ">State</label>
						<select :id="'callform-state' + oid "  name="addrstate" v-model="addrstate">
							<option value="">Select one...</option>
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="DC">District Of Columbia</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>
						</select>
					</div>
				</div>
			</div>
			<div class="row formrow">
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-tel' + oid ">Preferred Phone</label>
						<input type="text" :id="'callform-tel' + oid " v-model="tel" name="tel" v-mask='{ regex: "\\([2-9]\\d{2}\\) \\d{3}-\\d{4}" }'>
					</div>
				</div>
				<div class="small-12 medium-6 columns">
					<div class="fieldwrap">
						<label :for="'callform-calldate' + oid ">Date to Call</label>
						<div class="wrappedfield wrappedfield-date">
							<input type="text" :id="'callform-calldate' + oid " v-model="calldate_raw" name="calldate_raw" inputmode="numeric" v-mask="{ mask: '99/99/9999', placeholder: 'mm/dd/yyyy', clearMaskOnLostFocus: false }" v-on:change="updateTimeOptions()" @focus="$event.target.select()">
						</div>
					</div>
				</div>
			</div>
			<div class="row formrow">
				<div class="small-12 medium-6 end columns" v-show="dayHasOpenHours">
					<div class="fieldwrap">
						<label :for="'callform-time' + oid ">Time to Call</label>
						<div class="wrappedfield wrappedfield-time">
							<select id="'callform-time' + oid " v-model="calltime" name="calltime">
								<option value="">Select one...</option>
								<option v-bind:value="t" :key="'timeopt ' + t" v-for="t in timeOpts">{{ t }}</option>
							</select>
						</div>
						<div class="instrux">All times are displayed in {{ bizhours.timezone_name }} time.</div>
					</div>
				</div>
			</div>
			<div class="row formrow">
				<div class="small-12 columns">
					<div class="fieldwrap checkwrap consent-wrap">
						<input type="hidden" id="leadid_token" name="leadid_token"/>
						
						<input type="checkbox" name="consent" id="leadid_tcpa_disclosure" v-bind:class="'callform-consent' + oid " v-model="consent" value="1" aria-label="Terms and Conditions">
						<div class="consent-textwrap">
							<label for="leadid_tcpa_disclosure">By checking the box and clicking the "Schedule a Call Back" button below, you are providing your signed written consent to receive calls and/or texts from {{agency}} ("Agency") and its agents to market insurance products at the number (wireless or otherwise) you provided above, even if the number is on a federal, state or local do not call list. As part of that consent, you understand and agree that (1) those calls may be auto-dialed and use artificial or pre-recorded voice messages or SMS communications, and (2) your agreement to receive these calls or SMS communications is not required to purchase any goods or services, and (3) Agency may make additional attempts to call/message you if the desired time is unavailable and/or you are unable to be reached at the desired time. (4) You may revoke your consent by contacting {{agency}}. You further confirm that you have read and agree to the <a href="https://www.insuramatch.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://www.insuramatch.com/terms-and-conditions" target="_blank">Terms of Service</a>.
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row formrow">
				<div class="small-12 columns">
					<div class="buttonwrap">
						<button class="scheduleButton" v-show="isWorking !== true" v-on:click.prevent="scheduleCall()">Schedule a Call Back</button>
						<span class="workingMesg" v-show="isWorking === true"><img :src="asset('spinner.gif')" alt=""> Working...</span>
					</div>
					<p class="scheduleCallCancel" v-show="currentcust != true" style="text-align: center;"><a class="noTrack" href="#" v-on:click.prevent="cancelCallForm();">Cancel</a></p>
				</div>
			</div>
		</form>
		<form class="scheduleCallForm scheduleCallThanks" onsubmit="return false;" v-if="showThanks === true">
			<div class="formIntro">
				<h2>Thank you for requesting an appointment.</h2>
				<div class="row">
					<div class="small-12 large-8 large-offset-2 end columns">
						<p>We will reach out to you shortly.</p>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: {
		qid: {
			type: String,
			default: ""
		},
		firstname: {
			type: String,
			required: true
		},
		lastname: {
			type: String,
			required: true
		},
		email: {
			type: String,
			required: true
		},
		addrstate: {
			type: String,
			default: ""
		},
		tel: {
			type: String,
			required: true
		},
		bizhours: {
			type: Object,
			required: true
		},
		callnow: {
			type: String,
			required: true
		}, 
		currentcustomer: {
			type: String,
			default: 'No'
		}, 
		agency: {
			type: String, 
			default: 'InsuraMatch'
		}
	},
	data: function() {
		return {
			assetRoot: 'cdn/',
			isWorking: false,
			calldate_raw: '',
			calltime: '',
			consent: false,
			timeOpts: [], 
			showThanks: false
		}
	},
	computed: {
		oid: function(){
			if(this.qid != ''){
				return this.qid;
			}else{
				this.qid = Math.floor(Math.random() * 1000);
			}
			return this.qid;
		},
		calldate: function() {
			const pcs = this.calldate_raw.split('/');
			if (pcs.length > 2) {
				return pcs[2] + '-' + pcs[0] + '-' + pcs[1];
			}
			return '';
		},
		nextBizDay: function() {
			let found = false;
			let dayIdx;
			let nextDay = window.moment.tz('America/New_York');
			let i = 0; // prevent infinite loop
			while (found === false && i < 7) {
				nextDay.add(1, 'days');
				dayIdx = nextDay.format('e');
				if (window.is.array(this.bizhours[dayIdx])) {
					found = true;
					return nextDay.format('MM/DD/YYYY');
				}
				i++;
			}
			return window.moment.tz('America/New_York').format('MM/DD/YYYY'); // If business hours are not specified, just use today.
		},
		telDigits: function() {
			return this.tel.replace(/\D/g,'')
		},
		dayHasOpenHours: function() {
			var dayIdx = window.moment.tz(this.calldate, 'America/New_York').format('e');
			return (window.is.array(this.bizhours[dayIdx]));
		}, 
		currentcust: function() {
			return (this.currentcustomer == 'Yes');
		}
	},
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		},
		updateTimeOptions: function() {
			this.calltime = '';
			let calldate_error = this.callDateError();
			if (calldate_error !== false) {
				this.timeOpts = [];
				return;
			}
			let dayIdx = window.moment.tz(this.calldate, 'America/New_York').format('e');
			try {
				let opts = window.TrvTimes.generateTimeOptions(this.bizhours, dayIdx);
				if (window.is.array(opts)) { this.timeOpts = opts; }
			} catch (ex) {
				// Don't derail if we can't fetch times for the specified day.
			}
		},
		callDateError: function() {
			if (window.isBlank(this.calldate) === true || window.moment(this.calldate, 'YYYY-MM-DD', true).isValid() === false) {
				return "Please enter a preferred date to call.";
			}
			var dayIdx = window.moment.tz(this.calldate, 'America/New_York').format('e');
			if (window.is.array(this.bizhours[dayIdx]) === false) {
				return "Sorry, we have no call times available on " + window.moment.tz(this.calldate, 'America/New_York').format('dddd') + "s.";
			}
			if (window.moment.tz(this.calldate, 'America/New_York').isSameOrBefore(window.moment.tz('America/New_York'), 'day')) {
				return "Please choose a date that is in the future.";
			}
			return false;
		},
		scheduleCall: function() {
			// Check for required input.
			let errorsFound = [];
			if (window.isBlank(this.firstname)) { errorsFound.push("First name cannot be blank."); }
			if (window.isBlank(this.lastname)) { errorsFound.push("Last name cannot be blank."); }
			if (window.isBlank(this.email) === true || window.validate_email(this.email) === false) {
				errorsFound.push("Please enter a valid email address.");
			}
			if (window.isBlank(this.tel) === true || window.jQuery('#callform-tel' + this.oid)[0].inputmask.isComplete() === false) {
				errorsFound.push("Please enter a valid phone number.");
			}
			let calldate_error = this.callDateError();
			if (calldate_error !== false) {
				errorsFound.push(calldate_error);
			}
			if (window.isBlank(this.calltime) === true) {
				errorsFound.push("Please select a preferred time to call.");
			}
			if (window.isBlank(this.addrstate)) { errorsFound.push("Please select your state."); }
			if (errorsFound.length == 0 && this.consent === false) {
				errorsFound.push("Please check the box to agree to the Terms and Conditions.");
			}
			if (errorsFound.length > 0) {
				window.vex.dialog.alert(errorsFound[0]);
				return false;
			}
			
			// Calculate timestamp based on entered date & time.
			let datetime = window.moment.tz((this.calldate + ' ' + this.calltime), "YYYY-MM-DD HH:mm a", "America/New_York");
			if (datetime.isValid() === false) {
				window.vex.dialog.alert("Please enter your preferred date and time to receive a call.");
				return false;
			}
			// Prepare and send the API request.
			let leadid_token = '' ;
			if (window.jQuery('input[name=leadid_token]').length > 0) {
				leadid_token =  window.jQuery('input[name=leadid_token]').val();
			}

			let payload = {
					'first_name': this.firstname,
					'last_name': this.lastname,
					'state': this.addrstate,
					'email': this.email,
					'phone': this.telDigits,
					'callback_datetime': datetime.format('X'), // timestamp
					"call_indicator": this.consent,
					"sms_indicator": this.consent,
					"email_indicator": this.consent,
					"leadid_token": leadid_token
				};

			if (window.jQuery('input[name=consent_tcpa_certificate_url]').length > 0) {
				let tcpa_url = window.jQuery('input[name=consent_tcpa_certificate_url]').val();
				if(tcpa_url !== ''){
					payload.tcpa_certificate_url = tcpa_url;
				}
			}
		
			const requestData = {
				data: payload
			};

			if( window.RateApp !== undefined && window.RateApp.stateKnockoutPath == "im" 
				|| window.RateApp.stateKnockoutColor == 'blue' 
				|| window.RateApp.stateKnockoutColor == 'red' 
				|| window.RateApp.stateKnockoutColor == 'yellow' ){
				window.TrvSDK.getConfig(function(config){ 
					Whitelabel.updateConfig(config);
				}, 'insuramatch');
			}

			if (window.RateApp !== undefined && is.object(window.RateApp.latestConfig) === true 
				&& is.string(window.RateApp.latestConfig.adunit_key) === true 
				&& window.RateApp.latestConfig.adunit_key != '') {
				requestData.data.adunit_key = window.RateApp.latestConfig.adunit_key;
			}else{
				requestData.data.url = window.location.href;
			}

			// Fire the API request to schedule a call, and handle the response.
			this.isWorking = true; // Hide the button to prevent a double-submit.
			window.Analytics.notify_pennyhoarder('SCHEDULE_CALL');
			
			if (this.currentcust === true) {
				window.TrvSDK.scheduleACall(requestData, window.Rates.scheduleCall_customer_callback.bind(this));
			} else {
				window.TrvSDK.scheduleACall(requestData, window.Rates.scheduleCall_callback.bind(this));
			}

			if(this.isWorking === false){
				this.cancelCallForm();
			}
		},
		cancelCallForm: function() {
			window.Rates.cancelCallForm();
			if(this.$parent !== undefined && this.$parent.cancelCallForm !== undefined ){
				this.$parent.cancelCallForm();
			}
		}
	},
	mounted: function() {
		this.calldate_raw = this.nextBizDay;
		this.updateTimeOptions();
	}
}
</script>